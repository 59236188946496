import React from 'react';

import { Box } from '@core';
import { ContentBlock } from '@components';
import { CardsBlockSection } from '@containers';

const Features = ({ data, padding, ...props }) => (
  <Box {...padding}>
    <CardsBlockSection
      {...props}
      items={data}
      itemComponent={({ index, ...rest }) => (
        <Box pt={{ _: data.length < 3 ? index > 0 && 40 : null, lg: index > 0 && 80 }}>
          <ContentBlock {...rest} />
        </Box>
      )}
      itemsOnDesktop={1}
      itemsOnTablet={1}
      withSlider={data?.length > 2}
    />
  </Box>
);

export default Features;
